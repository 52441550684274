export default {
  methods: {
    goTo({ to, externalLink, link }) {
      if (externalLink) {
        window.open(externalLink, '_blank')
      } else if (link) {
        window.open(externalLink)
      } else {
        this.$router.push(to)
      }
    },
  },
}
