export const introRows = [
  {
    columns: [
      {
        type: 'text',
        classList: [
          'text-md-h3',
          'text-h4',
          // 'd-flex',
          // 'justify-end',
          // 'align-center',
          'text-center',
        ],
        text: 'Ankit Kante',
        cols: 12,
      },
      // {
      //   type: 'image',
      //   classList: ['text-md-h3', 'text-h4', 'd-flex', 'justify-left'],
      //   src:'me.jpg',
      //   cols: 4,
      //   maxHeight: 100,
      //   maxWidth: 98
      // }
    ],
  },
  {
    columns: [
      {
        type: 'text',
        classList: ['text-md-h5', 'text-h6', 'text-center'],
        text: 'B.Tech in Computer Science and Engineering from MANIT, Bhopal (M.P.) (2010-14)',
        cols: 12,
      },
    ],
  },
  {
    noGutters: true,
    columns: [
      {
        type: 'button-text',
        link: 'mailto:ankitkantewrites@gmail.com',
        classList: ['text-md-h4', 'text-h5', 'text-center'],
        text: 'ankitkantewrites@gmail.com',
        cols: 12,
      },
      // {
      //   type: 'button-text',
      //   link: 'tel:+918588869761',
      //   classList: ['text-md-h4', 'text-h5', 'text-center'],
      //   text: '+918588869761',
      //   cols: 12,
      // },
    ],
  },
  {
    justify: 'center',
    columns: [
      {
        type: 'button-icon',
        icon: 'mdi-linkedin',
        externalLink: 'https://www.linkedin.com/in/ankitkante/',
        classList: ['text-md-h4', 'text-h5', 'text-center'],
        lg: 1,
        cols: 3,
        color: '#0072b1',
      },
      {
        type: 'button-icon',
        icon: 'mdi-twitter',
        externalLink: 'https://twitter.com/ankitkante',
        classList: ['text-md-h4', 'text-h5', 'text-center'],
        lg: 1,
        cols: 3,
        color: '#1DA1F2',
      },
      // {
      //   type: 'button-icon',
      //   icon: 'mdi-goodreads',
      //   externalLink: 'https://www.goodreads.com/ankitkante',
      //   classList: ['text-md-h4', 'text-h5', 'text-center'],
      //   lg: 1,
      //   cols: 3,
      //   color: '#553b08',
      // },
    ],
  },
]
