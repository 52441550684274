<template>
  <v-card
    :width="width"
    :min-height="minHeight"
    :color="color"
    v-on="eventHandlers"
  >
    <v-card-title class="black--text">
      {{ cardTitle }}
    </v-card-title>
    <v-card-text class="black--text">
      {{ cardText }}
    </v-card-text>
  </v-card>
</template>

<script>
import ExternalLinkMixin from '@/mixins/ExternalLinkMixin'

export default {
  mixins: [ExternalLinkMixin],
  props: {
    minHeight: {
      type: [Number, String],
      default: 200,
    },
    width: [Number, String],
    cardTitle: {
      type: String,
      required: true,
    },
    cardText: String,
    color: String,
    href: String,
  },
  computed: {
    eventHandlers() {
      const handlers = {}
      if (this.href) {
        handlers['click'] = () => {
          this.goTo({ externalLink: this.href })
        }
      }
      return handlers
    },
  },
}
</script>
