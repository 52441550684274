export const workExperience = [
  {
    header: {
      icon: 'mdi-account',
      title: 'About',
    },
    body: [
      {
        content:
          'I am a front-end developer with 9 years of experience. Because of my stints in various early stage startups, I specialize in building web-apps from the ground up.',
      },
    ],
  },
  {
    header: {
      icon: 'mdi-briefcase',
      title: 'Work Experience',
    },
    body: [
      {
        subHeader: {
          src: 'falkonry.png',
          title: 'Lead Engineer at Falkonry',
          subTitle: 'Dec, 2017 - Present',
        },
        content:
          'Started as a Software Engineer in the frontend team, got promoted to SSE and now Lead Engineer.\nIn my current role, I act as the scrum master for our 2-week sprints, co-ordinate with our Product Manager on what stories need to be created for the features they envision, act as the lead developer for the frontend team and mostly reach out to people from time to time and try to understand and hopefully resolve their day to day issues.',
      },
      {
        subHeader: {
          src: 'dekkho.png',
          title: 'Senior Software Engineer - Full Stack at Dekkho',
          subTitle: 'Feb, 2017 - Oct, 2017',
        },
        content:
          "Dekkho was an OTT platform which hosted curated videos.\nAs the only front-end developer in the team, I created dekkho's video streaming website using Polymer. Polymer is a Google framework that uses WebComponents standards to promote re-usability on the web.\nI also worked on SEO of the website to increase page rank on Google. Its all about clean URLs & a dynamically generated sitemap.\nI also worked as the host of the show 'Trailer Todh' where I analysed latest movie trailers. This show was made for social media promotions of our website. The show gathered more than 1 lac views on Dekkho's official Facebook page.",
      },
      {
        subHeader: {
          src: 'sendd.png',
          title: 'Full Stack Developer at Sendd-Craftsvilla',
          subTitle: 'Jan, 2015 - Feb, 2017',
        },
        content:
          "Sendd started as a logistic aggregator which was later acquired by Craftsvilla.\n Here, I worked on the development of a virtual logistics platform that connected Craftsvilla's website with various logistics companies such as FedEx, Blue Dart etc.",
      },
      {
        subHeader: {
          src: 'newgen.png',
          title: 'Software Design Engineer',
          subTitle: 'Jun, 2014 - Jun, 2015',
        },
        content:
          "Worked on development of mobile version of OmniDocs using Newgen's Enterprise Mobility Platform(NEMP).\nOmniDocs is a Document Management System(DMS) for storing and managing documents.\nNEMP is a framework for the development of mobile apps. The client is based on Cordova/Phonegap technology which allows development of apps on all platforms(Android,iOS etc.).\nCoding is done in JavaScript as the Cordova apps are launched in native WebView(i.e.default Browser).",
      },
    ],
  },
  {
    header: {
      icon: 'mdi-server',
      title: 'Skills',
      noGutters: true,
      classList: ['ml-n3'],
    },
    body: [
      {
        type: 'bulletPoints',
        content:
          'Worked on various projects that used VueJS, Polymer, AngularJS, CSS3, HTML5, JavaScript',
      },
      {
        type: 'bulletPoints',
        content: 'Write unit tests for VueJS components',
      },
      {
        type: 'bulletPoints',
        content: 'End-to-End testing using Cypress',
      },
      {
        type: 'bulletPoints',
        content:
          'Efficient in Google searching. I once solved a back-end failure by finding a bug report on GitHub. The bug report link was found on the 2nd page of the search results.',
      },
    ],
  },
]
