<template>
  <v-row align="center" justify="space-around">
    <v-col cols="6" class="d-flex justify-end">
      <LifeCard
        :width="cardWidth"
        :min-height="cardMinHeight"
        v-bind="cards['movies']"
      />
    </v-col>
    <v-col cols="6">
      <LifeCard
        :width="cardWidth"
        :min-height="cardMinHeight"
        v-bind="cards['books']"
      />
    </v-col>
    <v-col cols="12" class="text-center text-lg-h4 text-h5">
      My life revolves around these 3 things
    </v-col>
    <v-col cols="6" class="d-flex justify-center">
      <LifeCard
        :width="cardWidth"
        :min-height="cardMinHeight"
        v-bind="cards['family']"
      />
    </v-col>
  </v-row>
</template>

<script>
import LifeCard from '@/components/LifeCard'

export default {
  metaInfo: {
    title: 'Life',
    titleTemplate: '%s | Ankit Kante',
  },
  components: {
    LifeCard,
  },
  data() {
    return {
      cards: {
        movies: {
          cardTitle: 'Movies',
          cardText:
            'I watch a lot of movies. I plan to become a movie blogger some day.',
          color: 'blue lighten-4',
        },
        books: {
          cardTitle: 'Books',
          cardText: 'Click here to see my Goodreads Profile',
          color: 'teal lighten-4',
          href: 'https://www.goodreads.com/ankitkante',
        },
        family: {
          cardTitle: 'Family',
          cardText: 'This is personal. Nothing to click here 😛',
          color: 'orange lighten-4',
        },
      },
    }
  },
  computed: {
    cardWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 175
    },
    cardMinHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 175
    },
  },
}
</script>
