<template>
  <v-row align="center">
    <v-col cols="12" class="text-center text-md-h4 text-h4">
      Looks like you are lost
    </v-col>
    <v-col cols="12" class="text-center text-md-h4 text-h4">
      Click
      <router-link to="/"> here </router-link>
      to go to home page
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo: {
    title: '404',
    titleTemplate: '%s | Ankit Kante',
  },
}
</script>
