import life from './life'
import Vue from 'vue'
import Vuex from 'vuex'
import work from './work'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    work,
    life,
  },
})
