<template>
  <v-container>
    <v-row
      v-if="header"
      align="end"
      justify="start"
      :no-gutters="header.noGutters"
      :class="header.classList"
    >
      <v-icon :size="$vuetify.breakpoint.name == 'md' ? 42 : 34" class="mr-2">
        {{ header.icon }}
      </v-icon>
      <div class="text-md-h4 text-h5">
        {{ header.title }}
      </div>
    </v-row>
    <template v-for="({ subHeader, content, type }, bodyIndex) in body">
      <v-row v-if="subHeader" :key="subHeader.title" align="center">
        <v-avatar v-if="subHeader.src" class="mr-3">
          <v-img
            :src="require('../assets/' + subHeader.src)"
            :alt="subHeader.src"
          />
        </v-avatar>
        <v-col class="pl-0">
          <div class="text-h6 grey--text" cols="12">
            {{ subHeader.title }}
          </div>
          <div class="text-subtitle-1 font-weight-light" cols="12">
            {{ subHeader.subTitle }}
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="type !== 'bulletPoints'"
        :key="content"
        :class="['mb-2', !subHeader && bodyIndex === 0 ? 'pt-3' : '']"
        align="center"
      >
        <div
          v-for="line in content.split('\n')"
          :key="line"
          class="text-body-1"
        >
          {{ line }}
        </div>
      </v-row>
      <v-list-item v-else :key="content" class="ml-n3 pl-2">
        <v-list-item-icon class="mr-0">
          <v-icon size="14"> mdi-checkbox-blank-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-body-1">
          {{ content }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-container>
</template>

<script>
export default {
  props: {
    header: Object,
    body: Array,
  },
}
</script>
