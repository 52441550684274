<template>
  <v-row align="center">
    <v-col cols="12" class="text-md-h1 text-h4 text-center">
      Welcome visitor!
    </v-col>
    <v-col cols="12" class="text-md-h4 text-subtitle-1 text-center">
      Choose a pill to proceed
    </v-col>
    <v-col cols="12" class="text-md-h4 text-subtitle-1 text-center">
      <v-chip color="error" class="mr-3 px-6" to="/work"> My Work </v-chip>
      <v-chip color="primary" class="px-6" to="/life"> My Life </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Ankit Kante',
    titleTemplate: null,
  },
}
</script>
