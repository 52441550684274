import Error from './pages/Error'
import Home from './pages/Home'
import Life from './pages/Life'
import VueRouter from 'vue-router'
import Work from './pages/Work'

const routes = [
  { path: '/', component: Home },
  { path: '/work', component: Work },
  { path: '/life', component: Life },
  { path: '*', component: Error },
]

export default new VueRouter({
  mode: 'history',
  routes,
})
