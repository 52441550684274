<template>
  <v-app>
    <v-app-bar app fixed dense>
      <router-link to="/" class="toolbar-title">
        <v-toolbar-title>Ankit Kante</v-toolbar-title>
      </router-link>

      <v-spacer />
      <v-btn to="/work" text class="d-none d-md-flex" active-class="no-active">
        Work
      </v-btn>
      <v-btn to="/life" text class="d-none d-md-flex" active-class="no-active">
        Life
      </v-btn>
      <v-btn to="/work" icon class="d-md-none d-flex" active-class="no-active">
        <v-icon>mdi-briefcase</v-icon>
      </v-btn>
      <v-btn to="/life" icon class="d-md-none d-flex" active-class="no-active">
        <v-icon>mdi-meditation</v-icon>
      </v-btn>
      <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
    }
  },
}
</script>

<style>
.toolbar-title {
  color: inherit !important;
  text-decoration: inherit !important;
}
html {
  overflow-y: auto !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
