import { introRows, workExperience } from '../data/work'

export default {
  namespaced: true,
  state: {
    introRows: [],
    workExperience: [],
  },
  mutations: {
    setIntroRows(state, data) {
      state.introRows = data
    },
    setWorkExperience(state, data) {
      state.workExperience = data
    },
  },
  actions: {
    async fetch({ commit }) {
      // const response = await fetch('../data/work/introRows.js')
      // const introRows = await response.json()
      commit('setIntroRows', introRows)
      commit('setWorkExperience', workExperience)
    },
  },
}
