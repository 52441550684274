<template>
  <v-container>
    <v-row
      v-for="(row, rowIndex) in introRows"
      :key="rowIndex"
      :justify="row.justify"
      :no-gutters="row.noGutters"
    >
      <v-col
        v-for="(cell, colIndex) in row.columns"
        :key="colIndex"
        :class="cell.classList"
        :cols="cell.cols"
        :lg="cell.lg"
        :offset="cell.offset"
      >
        <span v-if="cell.type === 'text'">{{ cell.text }}</span>
        <v-img
          v-if="cell.type === 'image'"
          :src="require('../assets/' + cell.src)"
          contain
          :max-height="cell.maxHeight"
          :max-width="cell.maxWidth"
        />
        <v-btn
          v-if="cell.type === 'button-icon'"
          icon
          :color="cell.color"
          @click="goTo(cell)"
        >
          <v-icon>{{ cell.icon }}</v-icon>
        </v-btn>
        <v-btn
          v-if="cell.type === 'button-text'"
          :href="cell.link"
          :color="cell.color"
          text
          class="text-lowercase"
        >
          {{ cell.text }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="contentRow in workExperience"
      :key="
        contentRow.header ? contentRow.header.title : contentRow.subHeader.title
      "
      class="pa-md-0 pa-3"
    >
      <work-detail v-bind="contentRow" class="mb-8" />
    </v-row>
  </v-container>
</template>

<script>
import ExternalLinkMixin from '@/mixins/ExternalLinkMixin'
import { mapState } from 'vuex'
import WorkDetail from '@/components/WorkDetail'

export default {
  metaInfo: {
    title: 'Work',
    titleTemplate: '%s | Ankit Kante',
  },
  components: {
    WorkDetail,
  },
  mixins: [ExternalLinkMixin],
  computed: {
    ...mapState('work', ['introRows', 'workExperience']),
  },
  mounted() {
    this.$store.dispatch('work/fetch')
  },
}
</script>
